@charset "utf-8";

// Set your brand colors
$ruvi-turvallisuus: #004F71;
$ruvi-olki: #CEB888;
$ruvi-vadelma: #D0006F;

$ruvi-hiili: #343841;
$ruvi-untuva: #F4F3F2;
$ruvi-helmi: #EEEEEE;
$ruvi-valkoinen: #FFFFFF;

$ruvi-siitepoly: #F7CE3C;
$ruvi-saparo: #F0B5A4;
$ruvi-tiili: #FF6F49;
$ruvi-sammal: #4C7C62;
$ruvi-vesi: #8FC7E8;
$ruvi-atomi: #4A4D79;

// Update Bulma's global variables
$primary: $ruvi-hiili;
$light: $ruvi-helmi;
$background: $ruvi-turvallisuus;

$title-color: $ruvi-siitepoly;

$modal-card-title-color: $ruvi-untuva;
$modal-card-head-background-color: $ruvi-turvallisuus;
$modal-card-body-background-color: $ruvi-untuva;

$table-color: $ruvi-hiili;
$table-head-cell-color: $ruvi-untuva;
$table-head-background-color: $ruvi-turvallisuus;
$table-foot-cell-color: $ruvi-untuva;
$table-foot-background-color: $ruvi-turvallisuus;

$button-background-color: $ruvi-vadelma;
$button-border-color: $ruvi-olki;
$button-color: $ruvi-untuva;
$button-hover-color: $ruvi-olki;
$button-disabled-background-color: $ruvi-hiili;

$pagination-color: $ruvi-untuva;
$pagination-hover-color: $ruvi-olki;

$dropdown-content-background-color: $ruvi-turvallisuus;
$dropdown-item-color: $ruvi-untuva;
$dropdown-menu-background-color: $ruvi-turvallisuus;
$dropdown-item-active-background-color: $ruvi-hiili;
$dropdown-item-active-color: $ruvi-olki;
$dropdown-item-hover-color: $ruvi-olki;
$dropdown-item-hover-background-color: $ruvi-hiili;
$dropdown-divider-background-color: #337295;

//$success: $ruvi-sammal;
$warning: $ruvi-siitepoly;
$primary-light: $ruvi-olki;

// Import only what you need from Bulma
//@import "../node_modules/bulma/sass/utilities/_all.sass";
//@import "../node_modules/bulma/sass/base/_all.sass";
//@import "../node_modules/bulma/sass/elements/_all.sass";
//@import "../node_modules/bulma/sass/form/_all.sass";
//@import "../node_modules/bulma/sass/components/dropdown.sass";
//@import "../node_modules/bulma/sass/components/modal.sass";
//@import "../node_modules/bulma/sass/components/pagination.sass";

// Import all from Bulma
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma-tooltip/src/sass/index.sass";
@import "../node_modules/bulma-switch/src/sass/index.sass";
@import "../node_modules/bulma-checkradio/src/sass/index.sass";
@import "../node_modules/bulma-pageloader/src/sass/index.sass";
