#ruvi-summary {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white;
    overflow-y: scroll !important;
}

.row {
    text-align: left;
    margin-bottom: 10px;
}

#ruvi-picture {
    max-width: 50%;
    margin-left: 25%;
}

.pageloader {
    background: #CEB888;
}

