#ruvi-control-camera {
  position:relative;
    height: 100%;
    width: 100%;
    z-index: 2;
}


#camera {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: white;
}

#save-photo {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: white;
}
#photo-labels {
  z-index: 100;
  position:absolute; 
  background:white; 
  border: 1px solid black; 
  width: 200px; 
  margin-top: 20px; 
  margin-left:20px; 
  padding:  7px 15px; 
  text-align: left; 
  -webkit-border-radius: 6px; 
  -moz-border-radiust: 6px; 
  border-radius: 6px; 
}
#photo-labels img { position: absolute; right: 5px; top: 5px; }

.ready-labels { 
  position: relative; 
  right: 5px; 
  top: 5px; 
}

#take-again {
  cursor: pointer;
  margin: 5px;
}
#get-labels {
  cursor: pointer;
  margin: 5px;
}
#save-button {
  cursor: pointer;
  margin: 5px;
}
#show-labels {
  cursor: pointer;
  margin: 5px;
}
#take-again-old {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
#get-labels-old {
  position: absolute;
  left: 150px;
  bottom: 10px;
}
#save-button-old {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.button-layout {
  position:absolute;
	margin-left: 25px;
  margin-right: 25px;
	margin-bottom: 5px;  
  bottom: 10px;
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.separator {
  border-bottom: 2px solid #5CC770;
}