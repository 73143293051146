.camera {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
}

.btn-take-photo {
  position: absolute;
  right: 10px;
  bottom: 2px;
  z-index: 1;
}
.button-photo-layout {
  position:absolute;
	margin-left: 25px;
  margin-right: 25px;
	margin-bottom: 0px;  
  bottom: 2px;
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.camera video#device_stream_display {
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.camera video#device_photo_memory {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
}

.message {
  z-index: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.message span {
  width: 50%;
}

.shooter {
  position: absolute;
  z-index: 2;
}