#ruvi-atributes {
    height: 100%;
    width: 100%;
    z-index: 2;
}

#photo-attributes {
    position: absolute;
    width: 100%;
    top: 20%;
}

#attributes-save-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
#infotext {
    position: absolute;
    bottom: 55px;
    left: 10;
    width: 100%;
    font-size:larger;
    font-weight: bold;
    color:rgb(255, 255, 255);
    background-color: rgba(92, 84, 84, 0.35);
}

.dropdown.is-active {
    display: block !important;
}

.dropdown.is-active .dropdown-menu {
    width: 100% !important;
    padding-left: 0.175rem !important;
    padding-right: 0.175rem !important;
}

.dropdown-item {
    padding-left: 0.175rem !important;
    padding-right: 0.175rem !important;
}